import { AngularEditorConfig } from "@kolkov/angular-editor";

export const UserRoles = {
    ROLE: {
        ADMIN: 'Admin',
        INSTITUTEADMIN: 'InstituteAdmin',
        OPERATIONS: 'Operations',
        TEACHER: 'Teacher',
        STUDENT: 'Student',
        CUSTOMER: 'Customer',
        SALESPERSON: 'SalesPerson',
        AGENT: 'Agent',
    }
};
export const Constants = {
    Empty_Guid: '00000000-0000-0000-0000-000000000000',
    Web_Client_id: 'notebook.edujournal.web',
    Style: {
        table: 'table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 text-gray-700',
        table_header_row: 'fw-bold fs-7 text-uppercase gs-0'
    },
    Source: {
        vimeo: 'VIMEO',
        azure: 'AZURE'
    },
    Local_time_zone: 'Asia/Kolkata',
    TaxConfig: {
        taxState: 'Karnataka',
        taxCountry: 'India'
    }
};
export const businessModelType = {
    B2B: 1,
    B2C: 2,
    All: 3,
};
export const ReferrerType = {
    Reseller: 1,
    ReferralProgram: 2,
    Agent: 3,
    Intitute: 4,
    SalesPerson: 5,
};

export const ScrollScss = {
    scrollButton: "p-4 hover rounded-circle w-40px h-40px border border-2 d-flex flex-center bg-white border-gray-500",

}

export const EditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '100px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
        { class: 'arial', name: 'Arial' },
        { class: 'times-new-roman', name: 'Times New Roman' },
        { class: 'calibri', name: 'Calibri' },
        { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    // customClasses: [
    //     {
    //         name: 'quote',
    //         class: 'quote',
    //     },
    //     {
    //         name: 'redText',
    //         class: 'redText'
    //     },
    //     {
    //         name: 'titleText',
    //         class: 'titleText',
    //         tag: 'h1',
    //     },
    // ],
    // uploadUrl: environment.apiUrl+'/api/others/UploadImageForTextEditor',
    // upload: (file: File) => { },
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
        ['indent', 'outdent',],
        ['insertImage', 'insertVideo',]
    ]
};

