import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    constructor() { }
    public blockLoader: boolean = false;

    showLoader() {
        Swal.fire({
            html: '<div class="d-flex align-items-center justify-content-center overflow-hidden"><span class="loader-icon me-4"></span> <span class="fs-4">Please wait...</span><div>',
            width: 250,
            timer: 100000,
            backdrop: 'rgba(255,255,255,0.2)',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            customClass: {
                container: 'swal-loading',
            },
            heightAuto: false
        });
    }

    hideLoader() {
        Swal.close();
    }

}

export const ApisWithoutLodingScreen = [
    environment.apiUrl + "/api/connect/LoggedInUser",
    environment.apiUrl + "/api/connect/token/refresh",
    environment.apiUrl + "/api/connect/revoke",
    environment.apiUrl + "/api/CandidateUpload/ProcessUpload",
    environment.apiUrl + '/api/Video/GetListWithPagination/0',
    environment.apiUrl + '/api/Document/GetListWithPagination/0',
    environment.apiUrl + '/api/Others/GetAseanCountries',
    environment.apiUrl + '/api/Institute/getUploads',
    environment.apiUrl + '/api/Topic/GetUploadList/0',
    environment.apiUrl + '/api/WatchedVideo/SaveVideoTime',
    environment.apiUrl + '/api/WatchedVideo/GetVideoTime',
    environment.apiUrl + '/api/ChatBot/Prompt',
    environment.apiUrl + '/api/connect/WebQRLogin',
    environment.apiUrl + '/api/ChatBot/getTopicTagDescription',
    environment.apiUrl + '/api/Institute/getSchoolNames',
    environment.apiUrl + '/api/ContentUsage/Save',
    environment.apiUrl + '/api/Dashboard/DashboardContinueLearning',
    environment.apiUrl + '/api/Dashboard/DashboardSubjectCards'
];
