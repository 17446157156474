import { AddressModel } from "src/app/models/address.model";
import { AppSignature } from "src/app/models/app-signature";
import { InstituteDTO } from "../../institute/models/institute-model";

export class InstituteGroup extends AppSignature {
    name: string;
    websiteUrl: string;
    domains: string;
    description: string;
    contactPersonName: string;
    email: string;
    phone: string;
    parentAddressID: string;
    affiliationNumber: string;
    numberOfCampus: number;
    status: number;
    parentAgentID: string;
    isLead: boolean;
}

export class InstituteGroupMap extends AppSignature {
    parentInstituteID: string;
    parentInstituteGroupID: string;
}

export class InstituteGroupDTO {
    instituteGroup: InstituteGroup = new InstituteGroup();
    instituteGroupMaps: Array<InstituteGroupMap> = [];
    address: AddressModel;
    instituteList: Array<InstituteDTO> = [];
}