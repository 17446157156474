import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { firstValueFrom, Observable } from "rxjs";
import { ApiResponse } from "src/app/models/api-response";
import { AuthService } from "src/app/modules/auth";
import { environment } from "src/environments/environment";

import { Constants, UserRoles } from "./constants";
import { InstituteModel, InstituteSource } from "src/app/modules/institute/models/institute-model";
import { LessonDTO, LessonTopicContentDetails } from "src/app/modules/class/models/lesson.model";
import { ClassHTTPService } from "src/app/modules/class/services/class-http.service";
import { StorageHelper } from "./storage-helper";
import { IpAPIModel } from "src/app/models/IpAPI.model";
import { CountryModel } from "src/app/models/country.model";
import { Guid } from "guid-typescript";

const API_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private authService: AuthService, private http: HttpClient, private storageHelper: StorageHelper, private _HTTPClassService: ClassHTTPService) { }


  isSysAdmin(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser && currentUser.staff.parentInstituteID == Guid.EMPTY) {
      currentUser.role.name == UserRoles.ROLE.ADMIN ? result = true : result = false;
    }
    return result;
  }

  isInstituteAdmin(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser && currentUser.staff.parentInstituteID != Guid.EMPTY) {
      currentUser.role.name == UserRoles.ROLE.INSTITUTEADMIN ? result = true : result = false;
    }
    return result;
  }

  isInstituteGroupAdmin(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser && currentUser.staff.parentInstituteGroupID != Guid.EMPTY) {
      currentUser.role.name == UserRoles.ROLE.INSTITUTEADMIN ? result = true : result = false;
    }
    return result;
  }

  isTeacher(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser && currentUser.staff.parentInstituteID != Guid.EMPTY && currentUser.staff.isTeachingStaff) {
      currentUser.role.name == UserRoles.ROLE.TEACHER ? result = true : result = false;
    }
    return result;
  }
  isStudent(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser) {
      currentUser.role.name == UserRoles.ROLE.STUDENT ? result = true : result = false;
    }
    return result;
  }
  isOperations(): boolean {
    const currentUser = this.authService.currentUser!;
    var result = false;
    if (currentUser) {
      currentUser.role.name == UserRoles.ROLE.OPERATIONS ? result = true : result = false;
    }
    return result;
  }
  isCustomer(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser) {
      currentUser.role.name == UserRoles.ROLE.CUSTOMER ? result = true : result = false;
    }
    return result;
  }

  isSalesPerson(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser) {
      currentUser.role.name == UserRoles.ROLE.SALESPERSON ? result = true : result = false;
    }
    return result;
  }
  isAgent(): boolean {
    const currentUser = this.authService.currentUser;
    var result = false;
    if (currentUser) {
      currentUser.role.name == UserRoles.ROLE.AGENT ? result = true : result = false;
    }
    return result;
  }
  isSubAgent(): boolean {
    const currentUser = this.authService.currentUser;
    var result = this.isAgent();
    if (result) {
      result = currentUser?.agent.parentAgentID != Constants.Empty_Guid;
    }
    return result;
  }

  isIFrameView() {
    const currentUser = this.authService.currentUser;
    if (currentUser?.institute.instituteSource == InstituteSource.BrainBoxSchool)
      return true;
    return false
  }

  userProfilePicture(): string {
    const currentUser = this.authService.currentUser;
    if ((this.isSysAdmin() || this.isTeacher() || this.isInstituteAdmin() || this.isSalesPerson() || this.isOperations()) && (currentUser?.staff.profilePicture != '')) {
      return currentUser?.staff.profilePicture ?? '';
    }
    if (this.isStudent() && currentUser?.student.profileImage != '') {
      return currentUser?.student.profileImage ?? '';
    }
    if (this.isCustomer() && currentUser?.customer.profileImage != '') {
      return currentUser?.customer.profileImage ?? '';
    }
    if (this.isAgent() && currentUser?.agent.profileImage != '') {
      return currentUser?.agent.profileImage ?? '';
    }
    return '';
  }

  currentUserDisplayName(): string {
    const currentUser = this.authService.currentUser;
    var name = "";
    if (this.isStudent()) {
      name = currentUser!.student.firstName.trim() + " " + currentUser!.student.lastName.trim();
    }
    if (this.isCustomer()) {
      name = currentUser!.customer.firstName.trim() + " " + currentUser!.customer.lastName.trim();
    }
    if ((this.isSysAdmin() || this.isTeacher() || this.isInstituteAdmin() || this.isSalesPerson() || this.isOperations())) {
      name = currentUser!.staff.firstName.trim() + " " + currentUser!.staff.lastName.trim();
    }
    if (this.isAgent()) {
      name = currentUser!.agent.name.trim();
    }
    var tempname = name.split(' ');
    name = '';
    tempname.forEach(element => {
      element = element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
      name = name + ' ' + element;
    });
    return name;
  }

  currentInstitute(): InstituteModel {
    if (this.authService.currentUser && this.authService.currentUser.institute)
      return this.authService.currentUser!.institute;
    else
      return new InstituteModel();
  }


  async getCountryList(): Promise<ApiResponse<Array<CountryModel>>> {
    return await firstValueFrom(this.http.get<ApiResponse<Array<CountryModel>>>(`${API_URL}/api/Others/GetCountries`));
  }
  async getAseanCountryList(): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.get<ApiResponse<any>>(`${API_URL}/api/Others/GetAseanCountries`));
  }

  async getIndianStatesList(): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.get<ApiResponse<any>>(`${API_URL}/api/Others/GetIndianStates`));
  }
  async getQRImage(qrdata: String): Promise<ApiResponse<string>> {
    return await firstValueFrom(this.http.get<ApiResponse<string>>(`${API_URL}/api/Others/QRImage/` + qrdata));
  }

  async LinkQRImage(data: any): Promise<ApiResponse<string>> {
    return await firstValueFrom(this.http.post<ApiResponse<string>>(`${API_URL}/api/Others/QRImage/`, data));
  }

  async GetListForPackageTree(packageId: any): Promise<ApiResponse<Array<any>>> {
    return await firstValueFrom(this.http.get<ApiResponse<Array<any>>>(`${API_URL}/api/Subject/GetListForPackageDropDown/${packageId}`));
  }

  async UploadImageForTextEditor(fileData: File): Promise<ApiResponse<any>> {
    return await firstValueFrom(this.http.post<ApiResponse<any>>(`${API_URL}/api/others/UploadImageForTextEditor`, fileData));
  }

  getIpApiInfo(): IpAPIModel | null {
    try {
      const lsValue: string | null = localStorage.getItem('_nb_rInfo_cf');
      if (lsValue) {
        var data = JSON.parse(lsValue) as IpAPIModel
        return data;
      }
    } catch (error) {
      console.error('LOCAL STORAGE PARSE ERROR', error)
    }
    return null;
  }

  getAllCountryList(): Array<CountryModel> {
    try {
      const lsValue: string | null = localStorage.getItem('nb_country');
      if (lsValue) {
        var data = JSON.parse(lsValue) as Array<CountryModel>
        return data;
      }
    } catch (error) {
      console.error('LOCAL STORAGE PARSE ERROR', error)
    }
    return [];
  }
  setAppMode(mode: string) {
    localStorage.setItem('nb_app_mode', mode);
  }

  getAppMode(): string {
    try {
      const lsValue: string | null = localStorage.getItem('nb_app_mode');
      if (lsValue) {
        return lsValue;
      }
    } catch (error) {
      console.error('LOCAL STORAGE PARSE ERROR', error)
    }
    return '';
  }
  setAllCountryList(data: Array<CountryModel>) {
    localStorage.setItem('nb_country', JSON.stringify(data));
  }

  myLessonCleanUp(data: LessonDTO) {
    return data;
  }
  topicContentSort(list: Array<LessonTopicContentDetails>) {

    list = list.sort(function (a, b) {
      let af = a.topicContent.type;
      let bf = b.topicContent.type;
      let as = a.topicContent.sortOrder;
      let bs = b.topicContent.sortOrder;

      // If first value is same
      if (af == bf) {
        return (as < bs) ? -1 : (as > bs) ? 1 : 0;
      } else {
        return (af < bf) ? -1 : 1;
      }
    });
    return list;
  }
}